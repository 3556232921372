<template>
  <span>
    <v-row justify="center" class="mt-10">
      <v-col v-for="company in companies" :key="company.sigla" cols="12" md="6">
        <v-card class="my-1">
          <v-card-title class="py-5" style="display: flex;">
            <v-sheet
              color="primary"
              elevation="4"
              class="text-start v-card-title-icon mb-n6 mr-3 pa-1"
            >
              <v-icon
                style="height: 64; width: 64;"
                v-text="`$mapa_${company.sigla.toLowerCase()}`"
              />
            </v-sheet>
            <h4>
              {{ company.name }}
            </h4>
          </v-card-title>
          <v-card-text>
            <v-data-table
              class="table-indicadores"
              multi-sort
              dense
              :headers="headers"
              :items="items[company.sigla]"
              :options="{ itemsPerPage: 10 }"
              :headerProps="{ sortByText: 'Ordenar Por' }"
              no-data-text="Nenhum registro disponível"
              no-results-text="Nenhum registro correspondente encontrado"
              :mobile-breakpoint="10"
              hide-default-footer
            >
              <template v-slot:[`item.amostra_regulatoria`]="{ item }">
                <span>
                  {{
                    item.amostra_regulatoria == null
                      ? "-"
                      : item.amostra_regulatoria
                        | parseNumberToFloatBrIgnoringNaN
                  }}
                </span>
              </template>
              <template v-slot:[`item.real_aneel`]="{ item }">
                <span>
                  {{
                    item.real_aneel == null
                      ? "-"
                      : item.real_aneel | parseNumberToFloatBrIgnoringNaN
                  }}
                </span>
              </template>
              <template v-slot:[`item.comparacao`]="{ item }">
                <v-tooltip
                  bottom
                  v-if="item.comparacao > 0 || item.comparacao < 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.comparacao > 0 ? 'success' : 'error'"
                      v-text="
                        item.comparacao > 0
                          ? 'mdi-check-circle'
                          : 'mdi-close-circle'
                      "
                    />
                  </template>
                  <span v-if="item.comparacao > 0">
                    A empresa possui mais medições amostrais do que a base
                    regulatória
                  </span>
                  <span v-if="item.comparacao < 0">
                    A empresa possui menos medições amostrais do que a base
                    regulatória
                  </span>
                </v-tooltip>
                <span>
                  {{
                    item.comparacao == null
                      ? "-"
                      : item.comparacao | parseNumberToFloatBrIgnoringNaN
                  }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService.js";

  export default {
    name: "DashboardNivelTensaoVisaoMedidoresAmostrais",
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      headers: [
        { text: "Tri", value: "tri" },
        { text: "Ano", value: "ano" },
        { text: "Amostra Regulatória", value: "amostra_regulatoria" },
        { text: "Real (site ANEEL)", value: "real_aneel" },
        { text: "Comparação Real x Regulatório", value: "comparacao" },
      ],
      items: {
        al: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 786,
            real_aneel: 761,
            comparacao: -25,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 804,
            real_aneel: 785,
            comparacao: -19,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 822,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 840,
            real_aneel: null,
            comparacao: null,
          },
        ],
        pi: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 1056,
            real_aneel: 983,
            comparacao: -73,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 1080,
            real_aneel: 1018,
            comparacao: -62,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 1104,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 1128,
            real_aneel: null,
            comparacao: null,
          },
        ],
        pa: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 1350,
            real_aneel: 1217,
            comparacao: -133,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 1380,
            real_aneel: 1228,
            comparacao: -152,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 1410,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 1440,
            real_aneel: null,
            comparacao: null,
          },
        ],
        ma: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 1350,
            real_aneel: 1196,
            comparacao: -154,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 1380,
            real_aneel: 1236,
            comparacao: -144,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 1410,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 1440,
            real_aneel: null,
            comparacao: null,
          },
        ],
        ap: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 426,
            real_aneel: 297,
            comparacao: -129,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 436,
            real_aneel: 403,
            comparacao: -33,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 446,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 456,
            real_aneel: null,
            comparacao: null,
          },
        ],
        rs: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 1056,
            real_aneel: 297,
            comparacao: -759,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 1080,
            real_aneel: 403,
            comparacao: -677,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 1104,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 1128,
            real_aneel: null,
            comparacao: null,
          },
        ],
        go: [
          {
            tri: 1,
            ano: 2024,
            amostra_regulatoria: 1506,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 2,
            ano: 2024,
            amostra_regulatoria: 1540,
            real_aneel: 1572,
            comparacao: 32,
          },
          {
            tri: 3,
            ano: 2024,
            amostra_regulatoria: 1574,
            real_aneel: null,
            comparacao: null,
          },
          {
            tri: 4,
            ano: 2024,
            amostra_regulatoria: 1608,
            real_aneel: null,
            comparacao: null,
          },
        ],
      },
      companies: [
        { name: "Equatorial Alagoas", sigla: "al" },
        { name: "Equatorial Piauí", sigla: "pi" },
        { name: "Equatorial Pará", sigla: "pa" },
        { name: "Equatorial Maranhão", sigla: "ma" },
        { name: "Equatorial Amapá", sigla: "ap" },
        { name: "Equatorial Rio Grande do Sul", sigla: "rs" },
        { name: "Equatorial Goiás", sigla: "go" },
      ],
    }),
    computed: {
      formattedCompetencia() {
        const [year, month] = this.competencia.split("-");

        return `${month}/${year}`;
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      getDados() {
        // Apenas atualiza os dados se for a visão selecionada
        if (this.activatedTab !== 4) return;

        // this.$emit("updateLoadingDados", true);

        // Promise.all([

        // ])
        //   .catch((err) => {
        //     console.error(err);
        //     this.$toast.error("Erro recuperar dados.", "", {
        //       position: "topRight",
        //     });
        //   })
        //   .finally(() => this.$emit("updateLoadingDados", false));
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
    },
  };
</script>

<style>
  .v-card-title-icon {
    position: relative;
    top: -40px;
    transition: 0.5s ease;
  }
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }

  .table-indicadores table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
    text-align: end;
  }
  .table-indicadores table thead tr th {
    font-size: 12px;
    height: 25px;
    text-align: end !important;
    font-weight: 500;
  }
</style>
